.csv-input {
    padding: 10px;
    display: block;
    margin: 15px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
.react-csv-input {
  text-align: center;
  margin: 30px auto;
}