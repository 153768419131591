$theme-colors: (
  "primary": #008cff
);
//$light: #dfefff;
//$body-color: #FFF;

.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
}

.cursor-pointer {
  cursor: pointer;
}

.Toastify__toast--info {
  min-height: inherit !important;
  background: #008cff !important;
}

// $sidebar-bg-color: #1d1d1d;
// $submenu-bg-color: rgba(255, 255, 255, 0.05);
// $submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
// $sidebar-color: #adadad;
// $highlight-color: #d8d8d8;
// $icon-bg-color: rgba(255, 255, 255, 0.05);
// $icon-size: 35px;
@import "../node_modules/bootstrap/scss/bootstrap";

html {
  color: $primary;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.7em;
  margin-top: 1.5em;
}

body {
  margin: 0;
  height: 100vh;
  color: #353535;
}
#root {
  height: 100%;
  .app {
    height: 100%;
    display: flex;
    position: relative;
    .page-wrap {
      display: flex;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .page-content {
        padding-bottom: 30px;
      }
    }
  }
}