
.sm-sidebar-icon {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.header-title {
    text-align: center;
    width: 100%;
    padding: 0.6rem;
    font-size: 1.5rem;
    color: #ffffff;
    height: 3.5rem;
}
.header-title-nav {
    min-height: 4.5rem;
}

.hamburger-menu {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: #fff;
    cursor: pointer;
    &:hover {
        color: #d6d6d6;
    }
}

$sidebar-bg-color: #1d1d1d !default;
$sidebar-color: #adadad !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';